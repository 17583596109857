import "./Sidebar.css";


import { useState, useContext } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme, useMediaQuery } from "@mui/material";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import TimelineOutlinedIcon from "@mui/icons-material/TimelineOutlined";
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import Button from "@mui/material/Button";
import { AuthContext, useAuth } from "../../components/auth";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/noir.png";
import ForwardToInboxOutlinedIcon from '@mui/icons-material/ForwardToInboxOutlined';

const Item = ({ title, to, icon, selected, setSelected, handleClick }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <MenuItem
      active={selected === title}
      style={{
        color: "white",

      }}
      onClick={() => { setSelected(title)
        if (handleClick) {
          handleClick();
        }
      
      }}
      
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = () => {
  const { logout } = useContext(AuthContext);

  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [selected, setSelected] = useState("Dashboard");

  const handleLogout = () => {
    logout();
  };

  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));

  return (

    <Box
      sx={{

        "& .pro-sidebar-inner": {
          background: `${colors.primary[300]} !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 30px !important",
  
        },
        "& .pro-inner-item:hover": {
          color: "#868dfb !important",
        },
        "& .pro-menu-item.active": {
          color: "#6870fa !important",
        },
      }}

    >
      <ProSidebar collapsed={isMobile}>

      {!isMobile && (
          <>
        <Box
          position="absolute"
          top={0}
          left={0}
          right={0}
          margin="auto"
          width="70px"
          height="140px"
          bgcolor="white"
          display="flex"
          justifyContent="center"
          alignItems="flex-end"
        >
          {/* Logo */}
          <img
            src={logo}
            alt="Logo"
            style={{ maxWidth: "100%", marginBottom: "5px" }}
          />
        </Box>

            <Box
              position="absolute"
              top="calc(140px + 8px)"
              left={0}
              right={0}
              textAlign="center"
              color="white"
              fontSize="13px"
            >
              KARLSONN
            </Box>
            <Menu iconShape="square">
              <Box marginTop="200px" ml="10%">
              <Typography
                  variant="h6"
                  color={colors.grey[900]}
                  sx={{ m: "15px 0 5px 20px" }}
                >
                  ANALYTICS
                </Typography>
                <Item
                  title="Dashboard"
                  to="/"
                  icon={<HomeOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />

                <Typography
                  variant="h6"
                  color={colors.grey[900]}
                  sx={{ m: "15px 0 5px 20px" }}
                >
                  MANAGE TEAM
                </Typography>
                <Item
                  title="Team"
                  to="/team"
                  icon={<PeopleOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="Invitations"
                  to="/team"
                  icon={<ForwardToInboxOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="Add User"
                  to="/adduser"
                  icon={<AddOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />

                <Typography
                  variant="h6"
                  color={colors.grey[900]}
                  sx={{ m: "15px 0 5px 20px" }}
                >
                  STATISTICS
                </Typography>
                <Item
                  title="Calendar"
                  to="/calendar"
                  icon={<CalendarTodayOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="Emergencies"
                  to="/line"
                  icon={<TimelineOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                   <Typography
                  variant="h6"
                  color={colors.grey[900]}
                  sx={{ m: "15px 0 5px 20px" }}
                >
                  ACCOUNT
                </Typography>
                <Item
              title="Settings"
              to="/contacts"
              icon={<PersonOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Log Out"
              icon={<LogoutOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
              handleClick={handleLogout}
            />
              </Box>
              {/* <Box
                display="flex"
                marginTop="20%"
                justifyContent="center"
                alignItems="center"
              >
                <Button onClick={handleLogout} variant="contained" size="large">
                  LOGOUT
                </Button>
              </Box> */}
            </Menu>
          </>
        )}
        {isMobile && (
          <Menu iconShape="square">
            <Item
              title="Dashboard"
              to="/"
              icon={<HomeOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Team"
              to="/team"
              icon={<PeopleOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Invitations"
              to="/team"
              icon={<ForwardToInboxOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Add User"
              to="/adduser"
              icon={<AddOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Calendar"
              to="/calendar"
              icon={<CalendarTodayOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Emergency Graph"
              to="/line"
              icon={<TimelineOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
                            <Item
              title="Settings"
              to="/contacts"
              icon={<PersonOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Log Out"
              icon={<LogoutOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
              handleClick={handleLogout}
            />
          </Menu>
        )}
      </ProSidebar>
    </Box>
    
  );
};

export default Sidebar;