//two calls being made to isloggedin, not problem but abit problem. 


import axios from "axios";
import React, { createContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { PacmanLoader } from 'react-spinners';
export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [userInfo, setUserInfo] = useState(null);
  // const [session, setSession] = useState(null);
  const [loggedIn,setIsLoggedIn] = useState(null);
  const navigate = useNavigate();

  const handleApiResponse = (response) => {
    let userInfo = response.data.user;

    console.log(userInfo);

    setUserInfo(userInfo);

    localStorage.setItem("userInfo", JSON.stringify(userInfo));

    // document.cookie = `session=${response.data.session.payload}; Secure`;

    console.log(response.data);
    console.log("User Session ID: " + response.data.session.id);
    console.log("User ID: " + response.data.session.user_id);

    if (userInfo.admin) {
      setIsLoggedIn(true);
      navigate("/");
    }

    
  };

  const login = async (email, passwd) => {
    setIsLoading(true);

    const requestData = { email, passwd };
    console.log("Request Data: ", requestData);

    try {
      const response = await axios.post(
        "https://id.karlsonn.eu/api/v1/auth/login",
        requestData
      );

      handleApiResponse(response);
    } catch (err) {
      console.log(err);
      console.log(err.response?.data);
      console.log(err.response?.status);
      console.log(err.response?.headers);
    } finally {
      setIsLoading(false);
    }
  };

  const logout = () => {
    setIsLoading(true);

    // Remove session cookie
    const cookieExpiration = "Thu, 01 Jan 1970 00:00:00 UTC";
    document.cookie = `session=; expires=${cookieExpiration}; path=/;`;

    localStorage.removeItem("userInfo");

    setIsLoggedIn(false);

    setIsLoading(false);

    // Check if session cookie has been removed
    const sessionCookie = document.cookie.split(";").some((cookie) => {
      const [name] = cookie.split("=");
      return name.trim() === "session";
    });

    if (!sessionCookie) {
      console.log("Session cookie has been successfully removed");
    }

    setTimeout(async () => {
      await navigate("/login");
    }, 100);
  };


  const isLoggedIn = async () => {
    try {
      let userInfo = localStorage.getItem("userInfo");
      userInfo = JSON.parse(userInfo);
  
      // Retrieve session from cookies
      const cookies = document.cookie.split(";");
      let session = null;
      cookies.forEach((cookie) => {
        const [name, value] = cookie.split("=");
        if (name.trim() === "session") {
          session = value;
        }
      });
     
  
      // Send session verification request to the server
      const requestBody = {
        payload: session,
      };
      
      console.log("Request body:", requestBody);
      
      // Send session verification request to the server
      const response = await axios.post("https://id.karlsonn.eu/api/v1/session/verify", requestBody);
  
      if (response.status === 204) {
        setIsLoggedIn(true);
        setUserInfo(userInfo);
        // setSession(session);

      } else {
        setIsLoggedIn(false);


      }
  
      setIsLoading(false);
    } catch (err) {
      console.log(`isLoggedIn error ${err}`);
    }
  };
  
  useEffect(() => {
    isLoggedIn().then(() => {
      setIsLoading(false);
    });
  }, []);
  
  if (isLoading) {
    return null;
  }

  return (
    <AuthContext.Provider
      value={{ login, logout, isLoading, userInfo, loggedIn }}
    >
      {children}
    </AuthContext.Provider>
  );
};














//two calls being made to the isLoggedIn function. One call is made inside the useEffect hook, 
//and another call is made inside the handleApiResponse function after a successful login.





// const handleApiResponse = (response) => {
//   // ...

//   console.log(response.data);

//   navigate("/");
// };

// const login = async (email, passwd) => {
//   setIsLoading(true);

//   const requestData = { email, passwd };
//   console.log("Request Data: ", requestData);

//   try {
//     const response = await axios.post(
//       "https://id.karlsonn.eu/api/v1/auth/login",
//       requestData
//     );

//     handleApiResponse(response);
//   } catch (err) {
//     // ...
//   } finally {
//     setIsLoading(false);
//   }
// };

// // ...

// const isLoggedIn = async () => {
//   try {
//     // ...

//     // Send session verification request to the server
//     const response = await axios.post(
//       "https://id.karlsonn.eu/api/v1/session/verify",
//       requestBody
//     );

//     if (response.status === 204) {
//       setIsLoggedIn(true);
//       setUserInfo(userInfo);
//       setSession(session);
//     } else {
//       setIsLoggedIn(false);
//     }

//     setIsLoading(false);
//   } catch (err) {
//     console.log(`isLoggedIn error ${err}`);
//   }
// };

// useEffect(() => {
//   if (loggedIn === null) {
//     isLoggedIn().then(() => {
//       setIsLoading(false);
//     });
//   }
// }, [loggedIn]);

// In the updated code, the isLoggedIn function is only called from the useEffect hook when the loggedIn 
// state is null. This ensures that the session verification is performed only once when 
// the component mounts and not after each successful login.

// Additionally, the handleApiResponse function now only handles the necessary logic after
//  a successful login and does not make any additional isLoggedIn calls.

//gpt solution



/// CORRECT

// const isLoggedIn = async () => {
//   try {
//     let userInfo = localStorage.getItem("userInfo");
//     userInfo = JSON.parse(userInfo);

//     // Retrieve session from cookies
//     const cookies = document.cookie.split(";");
//     let session = null;
//     cookies.forEach((cookie) => {
//       const [name, value] = cookie.split("=");
//       if (name.trim() === "session") {
//         session = value;
//       }
//     });
   

//     // Send session verification request to the server
//     const requestBody = {
//       payload: session,
//     };
    
//     console.log("Request body:", requestBody);
    
//     // Send session verification request to the server
//     const response = await axios.post("https://id.karlsonn.eu/api/v1/session/verify", requestBody);

//     if (response.status === 204) {
//       setIsLoggedIn(true);
//       setUserInfo(userInfo);
//       setSession(session);

//     } else {
//       setIsLoggedIn(false);


//     }

//     setIsLoading(false);
//   } catch (err) {
//     console.log(`isLoggedIn error ${err}`);
//   }
// };

// useEffect(() => {
//   isLoggedIn().then(() => {
//     setIsLoading(false);
//   });
// }, []);