import { ColorModeContext, useMode } from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import Dashboard from "./scenes/dashboard";
import Sidebar from "./scenes/global/Sidebar";
import { Routes, Route, Navigate, Outlet } from "react-router-dom";
import Team from "./scenes/team";
import Form from "./scenes/form";
import Calendar from "./scenes/calendar";
import Line from "./scenes/line";
import { AuthProvider } from "./components/auth";
import LoginPage from "./scenes/LoginPage";
import MaybeShow from "./components/MaybeShow/MaybeShow";
import RequireAuth from "./components/RequireAuth";
import Registration from "./scenes/registration";

function App() {
  const [theme, colorMode] = useMode();

  return (
    <AuthProvider>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          
          <div className="app">
            <MaybeShow>
              <Sidebar />
            </MaybeShow>
            <div style={{ height: "100%", width: "100%" }}>
            <main className="content">
              <Routes>
             
              <Route path="/" element={<RequireAuth><Dashboard /></RequireAuth>} />
                <Route path="/team" element={<RequireAuth><Team /></RequireAuth>} />
                <Route path="/calendar" element={<RequireAuth><Calendar /></RequireAuth>} />
                <Route path="/adduser" element={<RequireAuth><Form /></RequireAuth>} />
                <Route path="/line" element={<RequireAuth><Line /></RequireAuth>} />

                <Route path="/login" element={<LoginPage />} />

                <Route path="/registration" element={<Registration />} />

                <Route path="*" element={<Navigate to="/" replace />} />
                
              </Routes>
            </main>
            </div>
          </div>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </AuthProvider>
  );
}

export default App;
