import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from './auth';

const RequireAuth = ({ children }) => {
  const { loggedIn } = useContext(AuthContext);

  if (!loggedIn) {
    return <Navigate to="/login" />;
  }

  return children;
};

export default RequireAuth;





// import React, { useContext } from 'react';
// import { Navigate } from 'react-router-dom';
// import { AuthContext } from './auth';

// const RequireAuth = ({ children }) => {
//   const { loggedIn } = useContext(AuthContext);

//   if (!loggedIn) {
//     return <Navigate to="/login" />;
//   }

//   return children;
// };

// export default RequireAuth;