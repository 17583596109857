import React from "react";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
const MaybeShow = ({ children }) => {
  const location = useLocation();

  const [showSideBar, setShowSideBar] = useState(false);

  useEffect(() => {
    if (location.pathname === "/login") {
      setShowSideBar(false);
    } else {
      setShowSideBar(true);
    }
  }, [location]);

  return showSideBar ? children : null;
};

export default MaybeShow;
