import { Box, Button, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useContext } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { AuthContext } from "../../components/auth";
import logo from "../../assets/kerig-white.png";

const LoginPage = () => {
  const { login } = useContext(AuthContext);
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const handleFormSubmit = async (values) => {
    const { email, password } = values;
    login(email, password);
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      position="relative"
    >
      {/* Black vertical rectangle */}
      <Box
        position="absolute"
        top={0}
        left={0}
        right={0}
        margin="auto"
        width="56px"
        height="112px"
        bgcolor="#0c101b"
        display="flex"
        justifyContent="center"
        alignItems="flex-end"
      >
        {/* Logo */}
        <img
          src={logo}
          alt="Logo"
          style={{ maxWidth: "100%", marginBottom: "5px" }}
        />
      </Box>

      <Box
        position="absolute"
        top="calc(112px + 8px)" // Adjust the value based on the height and spacing
        left={0}
        right={0}
        textAlign="center"
        color="black"
        fontSize="10px"
        // fontWeight="bold"
      >
        KARLSONN
      </Box>

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(1, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 1" },
              }}
            >

              <TextField
                variant="standard"
                type="text"
                label="Email"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
                name="email"
                error={!!touched.email && !!errors.email}
                helperText={touched.email && errors.email}
                sx={{gridColumn: "span 1 "   }}
              />
              <TextField
                variant="standard"
                type="text"
                label="Password"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.password}
                name="password"
                error={!!touched.password && !!errors.password}
                helperText={touched.password && errors.password}
                sx={{ gridColumn: "span 1" 
                    }}
              />
              <Button
                type="submit"
                color="primary"
                variant="contained"
                disabled={!values.email || !values.password} 
                sx={{ gridColumn: "span 1"  
                                  }}
                // Disable button when fields are empty
              >
                Login
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

const checkoutSchema = yup.object().shape({
  email: yup.string().required("Required"),
  password: yup.string().required("Required"),
});

const initialValues = {
  email: "",
  password: "",
};

export default LoginPage;

// auth.login(user)
// navigate('/', {replace: true})

// axios
//   .post("https://bhv-api.kerig.ee/api/v1/auth/login", values.data)
//   .then((response) => {
//     const data = response.data;
//     const session = data.session.payload;
//     if (!session) {
//       alert("Unable to login");
//       return;
//     }
//     localStorage.clear();
//     localStorage.setItem("session", session);
//     setTimeout(() => {
//       navigate("/");
//     }, 500);
//   })
//   .catch((error) => {
//     alert("OOps");
//   });
