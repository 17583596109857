import { Box, Button, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import axios from "axios";
import { useState } from "react";
import Checkbox from "@mui/material/Checkbox";

const checkoutSchema = yup.object().shape({
  firstName: yup.string().required("Required"),
  lastName: yup.string().required("Required"),
  email: yup.string().email("Invalid Email").required("Required"),
});

const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
};

const Form = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [admin, setAdminState] = useState(false);

  const handleChangeBox = () => {
    setAdminState(!admin)
  }

  const handleFormSubmit = async (values, { resetForm }) => {
    const storedUserInfo = localStorage.getItem("userInfo");
    let orgValue = "";

    if (storedUserInfo) {
      const userInfo = JSON.parse(storedUserInfo);
      orgValue = userInfo.org;
    }

    const data = {
      org: orgValue,
      email: values.email,
      fname: values.firstName,
      lname: values.lastName,
      admin 
    };

    console.log("Data: ", data);

    try {
      const headers = {
        "Content-Type": "application/json",
      };

      const response = await axios.post(
        "https://id.karlsonn.eu/api/v1/invitation/create",
        data,
        { headers, withCredentials: true }
      );

      console.log("Response Data: ", response.data);
      console.log("Response Status: ", response.status);

      if (response.status === 201) {
        setSuccessMessage("USER HAS SUCCESSFULLY BEEN ADDED");
        resetForm();

        setTimeout(() => {
          setSuccessMessage("");
        }, 5000);
      }
    } catch (error) {
      console.error("Error: ", error);
      console.error("Error Response Message: ", error.response.data.message);
      setErrorMessage(error.response.data.message);
      setTimeout(() => {
        setErrorMessage("");
      }, 7000);
    }
  };

  return (
    <Box m="20px">
      <Header title="CREATE USER" subtitle="Create a New User Profile" />
      <Box m="20px">
        {successMessage && (
          <Box mt="20px" bgcolor="green" p="10px">
            {successMessage}
          </Box>
        )}
        {errorMessage && (
          <Box mt="20px" bgcolor="red" p="10px">
            {errorMessage}
          </Box>
        )}
      </Box>

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="First Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.firstName}
                name="firstName"
                error={!!touched.firstName && !!errors.firstName}
                helperText={touched.firstName && errors.firstName}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Last Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.lastName}
                name="lastName"
                error={!!touched.lastName && !!errors.lastName}
                helperText={touched.lastName && errors.lastName}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Email"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
                name="email"
                error={!!touched.email && !!errors.email}
                helperText={touched.email && errors.email}
                sx={{ gridColumn: "span 4"   }}
              />
            </Box>



            
            <div>
              <label>
                <input type="checkbox"
                admin={admin}
                onChange={handleChangeBox} />
                Assign user Administrator role
              </label>
              <p>Is "Administrator" checked? {admin.toString()}</p>
            </div>
            

            <Box display="flex" justifyContent="end" mt="50px">
              <Button type="submit" color="secondary" variant="contained">
                Create New User
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default Form;
